import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Welcome to "Galactic Quest: Adventure Beyond the Stars"!
			</title>
			<meta name={"description"} content={"Embark on an interstellar journey filled with breathtaking galaxies, cosmic battles, and epic quests. \"Galactic Quest\" invites you to become a space explorer, uncover hidden secrets of the universe, and forge alliances with alien civilizations."} />
			<meta property={"og:title"} content={"Welcome to \"Galactic Quest: Adventure Beyond the Stars\"!"} />
			<meta property={"og:description"} content={"Embark on an interstellar journey filled with breathtaking galaxies, cosmic battles, and epic quests. \"Galactic Quest\" invites you to become a space explorer, uncover hidden secrets of the universe, and forge alliances with alien civilizations."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
		</Components.Header>
		<Section padding="112px 0 110px 0" background="--color-primary" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Welcome to "Galactic Quest: Adventure Beyond the Stars"!
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Embark on an interstellar journey filled with breathtaking galaxies, cosmic battles, and epic quests. "Galactic Quest" invites you to become a space explorer, uncover hidden secrets of the universe, and forge alliances with alien civilizations.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Button
						padding="15px 38px 15px 38px"
						background="#d71a59"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						letter-spacing="1px"
						margin="0px 0px 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="#d71a59"
						hover-color="--darkL1"
						hover-background="rgba(215, 26, 89, 0.03)"
						type="link"
						text-decoration-line="initial"
						href="https://play.google.com/store/apps/details?id=com.TMGamedevelopment.GalaxyQuest&hl=en"
					>
						DOWNLOAD GAME
					</Button>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://uploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13:45:56.477Z"
					width="100%"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
					overflow="visible"
					object-fit="contain"
					srcSet="https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13%3A45%3A56.477Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13%3A45%3A56.477Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13%3A45%3A56.477Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13%3A45%3A56.477Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13%3A45%3A56.477Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13%3A45%3A56.477Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/1-removebg-preview.png?v=2024-11-11T13%3A45%3A56.477Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" id="about">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				lg-display="flex"
				lg-justify-content="center"
				padding="0px 16px 0px 0px"
				lg-padding="0px 0px 0px 0px"
			>
				<Box
					display="grid"
					grid-template-rows="repeat(9, 60px)"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="16px"
					max-width="480px"
					lg-margin="0px 0px 16px 0px"
					sm-grid-template-rows="repeat(9, 10vw)"
				>
					<Image
						src="https://uploads.quarkly.io/6731e8639335410018c7b49f/images/game-6.webp?v=2024-11-11T13:44:28.794Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-row="1 / span 5"
						grid-column="1 / span 1"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-6.webp?v=2024-11-11T13%3A44%3A28.794Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-6.webp?v=2024-11-11T13%3A44%3A28.794Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-6.webp?v=2024-11-11T13%3A44%3A28.794Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-6.webp?v=2024-11-11T13%3A44%3A28.794Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-6.webp?v=2024-11-11T13%3A44%3A28.794Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-6.webp?v=2024-11-11T13%3A44%3A28.794Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-6.webp?v=2024-11-11T13%3A44%3A28.794Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/6731e8639335410018c7b49f/images/game-4.webp?v=2024-11-11T13:44:28.784Z"
						border-radius="24px"
						object-fit="cover"
						grid-column="2 / span 1"
						grid-row="2 / span 3"
						width="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-4.webp?v=2024-11-11T13%3A44%3A28.784Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-4.webp?v=2024-11-11T13%3A44%3A28.784Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-4.webp?v=2024-11-11T13%3A44%3A28.784Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-4.webp?v=2024-11-11T13%3A44%3A28.784Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-4.webp?v=2024-11-11T13%3A44%3A28.784Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-4.webp?v=2024-11-11T13%3A44%3A28.784Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-4.webp?v=2024-11-11T13%3A44%3A28.784Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/6731e8639335410018c7b49f/images/game-2.webp?v=2024-11-11T13:44:28.781Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="1 / span 1"
						grid-row="6 / span 3"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-2.webp?v=2024-11-11T13%3A44%3A28.781Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-2.webp?v=2024-11-11T13%3A44%3A28.781Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-2.webp?v=2024-11-11T13%3A44%3A28.781Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-2.webp?v=2024-11-11T13%3A44%3A28.781Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-2.webp?v=2024-11-11T13%3A44%3A28.781Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-2.webp?v=2024-11-11T13%3A44%3A28.781Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-2.webp?v=2024-11-11T13%3A44%3A28.781Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://uploads.quarkly.io/6731e8639335410018c7b49f/images/game-5.webp?v=2024-11-11T13:44:28.782Z"
						border-radius="24px"
						object-fit="cover"
						width="100%"
						grid-column="2 / span 1"
						grid-row="5 / span 5"
						height="100%"
						object-position="20%"
						srcSet="https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-5.webp?v=2024-11-11T13%3A44%3A28.782Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-5.webp?v=2024-11-11T13%3A44%3A28.782Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-5.webp?v=2024-11-11T13%3A44%3A28.782Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-5.webp?v=2024-11-11T13%3A44%3A28.782Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-5.webp?v=2024-11-11T13%3A44%3A28.782Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-5.webp?v=2024-11-11T13%3A44%3A28.782Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-5.webp?v=2024-11-11T13%3A44%3A28.782Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="20px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				lg-width="100%"
				lg-padding="24px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					About the Game
				</Text>
				<Text margin="0px 0px 0px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					"Galactic Quest: Adventure Beyond the Stars" is a space-based adventure game that transports players to uncharted galaxies, allows them to build their fleets, and participate in space battles. It’s a thrilling journey for those who seek cosmic exploration and intergalactic diplomacy.
				</Text>
				<Button
					padding="15px 38px 15px 38px"
					background="#d71a59"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					letter-spacing="1px"
					margin="2rem 0px 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="#d71a59"
					hover-color="--darkL1"
					hover-background="rgba(215, 26, 89, 0.03)"
					type="link"
					text-decoration-line="initial"
					href="https://play.google.com/store/apps/details?id=com.TMGamedevelopment.GalaxyQuest&hl=en"
				>
					DOWNLOAD GAME
				</Button>
			</Box>
		</Section>
		<Section background="--color-dark" padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="40%"
				padding="15px 60px 15px 15px"
				lg-width="100%"
				lg-align-items="center"
				lg-display="flex"
				lg-justify-content="center"
				lg-flex-direction="column"
				lg-padding="15px 15px 15px 15px"
				lg-margin="0px 0px 30px 0px"
			>
				<Text margin="0px 0px 24px 0px" font="--headline1" color="#ffffff">
					Stats
				</Text>
				<Text font="--base" color="--light" lg-text-align="center" margin="0px 0px 0px 0px">
					Embark on a cosmic journey across galaxies, face interstellar threats, and collect rare artifacts as you traverse the vast universe in *Galactic Quest: Adventure Beyond the Stars*.{" "}
				</Text>
			</Box>
			<Box width="60%" display="flex" lg-width="100%" sm-flex-wrap="wrap">
				<Box
					width="33.33333%"
					padding="15px 15px 15px 15px"
					display="flex"
					align-items="center"
					justify-content="center"
					flex-direction="column"
					lg-width="50%"
					sm-width="100%"
				>
					<Text margin="0px 0px 16px 0px" font="--headline1" color="#ffffff">
						87%
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--orange" text-align="center">
						Galactic Exploration Rate
					</Text>
				</Box>
				<Box
					width="33.33333%"
					padding="15px 15px 15px 15px"
					display="flex"
					align-items="center"
					justify-content="center"
					flex-direction="column"
					lg-width="50%"
					sm-width="100%"
				>
					<Text margin="0px 0px 16px 0px" font="--headline1" color="#ffffff">
						75%
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--orange" text-align="center">
						Artifact Collection Progress
					</Text>
				</Box>
				<Box
					width="33.33333%"
					padding="15px 15px 15px 15px"
					display="flex"
					align-items="center"
					justify-content="center"
					flex-direction="column"
					lg-width="50%"
					sm-width="100%"
				>
					<Text margin="0px 0px 16px 0px" font="--headline1" color="#ffffff">
						92%
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--orange" text-align="center">
						Intergalactic Missions Completed
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 8px 0px"
					font="--lead"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 16px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					<Strong sm-text-align="left">
						Everything You Need
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="normal 900 42px/1.2 --fontFamily-serifGaramond"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Extraordinary Features
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Experience a thrilling journey across galaxies with advanced tech, mystical artifacts, and diverse alien lifeforms.
				</Text>
			</Box>
			<Box
				width="60%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px 32px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				md-grid-gap="36px "
			>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Dynamic Galactic Maps
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Explore ever-evolving star maps with new planets and challenges emerging on every journey.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Artifact Collection
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Seek out rare and ancient artifacts to unlock powers and uncover the mysteries of the cosmos.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Alien Alliances
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Form alliances or face off with diverse alien species, each with unique cultures and abilities.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Advanced Spacecraft Customization
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Upgrade and personalize your spacecraft with cutting-edge technology to survive cosmic dangers.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Immersive Storyline
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Engage with a deep narrative that responds to your choices, affecting outcomes and alliances.
						</Text>
					</Box>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" flex-direction="row">
					<Icon
						display="block"
						category="md"
						icon={MdDone}
						size="20px"
						color="--primary"
						margin="2px 16px 0px 0px"
					/>
					<Box display="flex" flex-direction="column">
						<Text
							as="h1"
							margin="0px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
						>
							<Strong>
								Cosmic Combat
							</Strong>
						</Text>
						<Text
							as="h1"
							margin="12px 0px 0px 0px"
							font="--base"
							color="--dark"
							width="100%"
							md-width="100%"
							text-align="left"
							lg-margin="8px 0px 0px 0px"
						>
							Battle space pirates, hostile creatures, and rival explorers using a robust, tactical combat system.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="50px 0 50px 0" sm-padding="80px 0 50px 0" quarkly-title="Hero-11">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
				lg-align-items="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					padding="80px 0px 80px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					sm-padding="0 0px 50px 0px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						padding="7px 20px 7px 20px"
						margin="0px 0px 30px 0px"
						border-radius="25px"
						border-color="--color-lightD2"
						sm-flex-direction="column"
						background="#ffe9f1"
						color="#a91345"
					>
						<Text margin="0px 10px 0px 0px" color="#d71a59" font="--base" sm-margin="0px 0 5px 0px">
							Outer Space Exploration
						</Text>
					</Box>
					<Text margin="0px 0px 30px 0px" font="normal 700 42px/1.2 --fontFamily-sans" sm-font="normal 700 36px/1.2 --fontFamily-sans" color="--darkL2">
						Galactic Quest: Adventure Beyond the Stars
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="--dark">
						Set out on a legendary quest through space to uncover mysteries, confront cosmic foes, and build alliances that shape your destiny.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-width="100%"
						flex-direction="column"
					>
						<Button
							padding="15px 38px 15px 38px"
							background="#d71a59"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							letter-spacing="1px"
							margin="0px 0px 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="#d71a59"
							hover-color="--darkL1"
							hover-background="rgba(215, 26, 89, 0.03)"
							type="link"
							text-decoration-line="initial"
							href="https://play.google.com/store/apps/details?id=com.TMGamedevelopment.GalaxyQuest&hl=en"
						>
							DOWNLOAD GAME
						</Button>
					</Box>
				</Box>
				<Image
					src="https://uploads.quarkly.io/6731e8639335410018c7b49f/images/game-3.webp?v=2024-11-11T13:44:28.779Z"
					display="block"
					width="40%"
					object-fit="cover"
					sm-height="500px"
					height="100%"
					lg-width="100%"
					lg-height="auto"
					border-radius="24px"
					srcSet="https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-3.webp?v=2024-11-11T13%3A44%3A28.779Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-3.webp?v=2024-11-11T13%3A44%3A28.779Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-3.webp?v=2024-11-11T13%3A44%3A28.779Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-3.webp?v=2024-11-11T13%3A44%3A28.779Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-3.webp?v=2024-11-11T13%3A44%3A28.779Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-3.webp?v=2024-11-11T13%3A44%3A28.779Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6731e8639335410018c7b49f/images/game-3.webp?v=2024-11-11T13%3A44%3A28.779Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text3" />
			<Override slot="link5" />
			<Override slot="link6" />
			<Override slot="text2" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66fbc4301b94b10024315de0"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});